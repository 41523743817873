.menu {
  position: fixed;

  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  transform: translateX(100%);
  z-index: -1;

  transition: transform .3s ease-out .3s, z-index .3s ease-out;

  &.active {
    transform: translateX(0);
    z-index: 990;
  }

  &__body {
    width: 100%;
    height: 100%;
    background-color: #437d9d;
    padding-top: 13rem;
  }

  .nav {
    &__list {
      display: block;
    }

    &__item {
      border-bottom: 1px solid #fff;
    }

    &__link {
      width: 100%;
      color: #fff;
    }
  }
}