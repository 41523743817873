.header {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  transition: transform .5s ease-out;
  // display: none; // временно

  &__container {
    // background-color: #eff6f6;
    flex-grow: 1;
    position: relative;
    z-index: 999;

    @include tablet {
      max-width: 100%;
      width: 100%;
      padding: 0;
    }
  }

  &__container,
  &__right,
  &__buttons,
  &__top {
    display: flex;
  }

  &__right {
    flex-direction: column;

    transition: background-color .3s ease-out, width .3s ease-out;
    will-change: width;
  }

  &__logo {
    border-bottom: 1px solid #397a9e;
    border-left: 1px solid #397a9e;
    border-right: 1px solid #397a9e;

    padding: 1rem 1.5rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color .3s ease-out .3s;
    flex-grow: 1;

    @include tablet {
      width: 9rem;
      padding: 0.5rem;
    }

    a {
      display: block;
    }

    img {
      display: block;
      height: 8.8rem;
      width: 25.6rem;

      @include tablet {
        width: 14rem;
        height: auto;
      }
    }
  }

  &__buttons {
    padding: 1.25rem 2rem;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    border-right: 1px solid #397a9e;
    transition: border-color .3s ease-out .3s;

    @include tablet {
      padding: 1.15rem 0.45rem;
      gap: 3rem;
      width: 100%;
      height: 50%;
    }
  }

  &__btn {
    display: block;
    width: 3.5rem;
    height: 3.5rem;

    @include tablet {
      width: 2.7rem;
      height: 2.7rem;
    }

    svg {
      width: 100%;
      height: 100%;
      fill: #397A9E;

      transition: fill .3s ease-out .3s;
    }
  }

  &__top {
    height: 50%;
    border-bottom: 1px solid #397a9e;
    transition: border-color .3s ease-out .3s;

    @include tablet {
      flex-wrap: wrap;
      height: 100%;
    }
  }

  &__cat {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    background-color: #397a9e;
    min-width: 28rem;

    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
    color: #fff;

    @include tablet {
      display: none;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.8rem 2rem;
    font-weight: 400;
    font-size: 1.8rem;
    text-align: center;
    color: var(--text-color);
    width: 19.6rem;

    border-right: 1px solid #397a9e;

    @include tablet {
      display: none;
    }
  }

  &__nav {
    display: flex;
    height: 50%;
    border-bottom: 1px solid #397a9e;

    @include tablet {
      display: none;
    }
  }

  &__burger {

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    width: 7rem;
    flex-shrink: 0;
    border-bottom: 1px solid #397a9e;

    display: none;
    transition: border-color .3s ease-out .3s;

    @include tablet {
      display: flex;
    }
  }
}

.burger {
  width: 100%;
  position: relative;

  &__line {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: var(--text-color);
    transition: background-color .3s ease-out .3s;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--text-color);
      transition: background-color .3s ease-out .3s;
    }

    &::before {
      top: -1rem;
    }

    &::after {
      top: 1rem;
    }
  }
}


.header-search {
  flex-grow: 1;
  position: relative;
  display: block;
  border-right: 1px solid var(--text-color);
  height: 100%;
  transition: border-color .3s ease-out .3s;

  @include tablet {
    width: 100%;
    height: 50%;
  }

  &__label,
  &__input {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__label {
    background-color: #dcf3f4;
  }

  &__input {
    background: none;
    border: none;
    padding: 1rem;
    padding-right: 8rem;

    &.focus-visible {
      outline: none;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    width: 3.5rem;
    height: 3.5rem;

    svg {
      width: 100%;
      height: 100%;
      fill: var(--text-color);

      transition: fill .3s ease-out .3s;
    }
  }
}


.header.logo-down {
  .header__logo {
    align-items: flex-end;
    padding: .7rem 5rem;

    img {
      height: 4.6rem;
      width: 17.8rem;
    }
  }

}

.header.high-width {
  .nav__list {
    flex-grow: 1;
  }

  .header__right {
    width: 100%;
    background-color: #eff6f6;
  }
}

.header.is-third {
  .header__m {
    opacity: 1;
    pointer-events: unset;
  }
}

.header__m {
  position: absolute;
  right: var(--container-offset);
  top: 100%;
  width: 19.6rem;
  height: 5rem;
  background-color: #397a9e;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  opacity: 0;
  pointer-events: none;

  transition: opacity .3s ease-out .3s;

  .m {
    position: relative;

    width: 3rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &__line {
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: #fff;
      transition: transform .3s ease-out .3s;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
      }

      &::before {
        top: -6px;
        transition: opacity .3s ease-out;
      }

      &::after {
        top: 6px;
        transition: transform .3s ease-out;
      }
    }
  }


  &.active {


    .m__line {
      transform: rotate(45deg);
      transform-origin: center;

      &::before {
        opacity: 0;
      }

      &::after {
        transform: rotate(90deg) translate(-4px);

      }
    }
  }
}