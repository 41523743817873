.nav {
  &__list {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #397a9e;

    transition: flex-grow .3s ease-out;
    will-change: flex-grow;
  }

  &__item {
    height: 100%;
  }

  &__link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.8rem 2rem;
    font-weight: 400;
    font-size: 1.8rem;
    text-align: center;
    color: var(--text-color);
    transition: background-color .3s ease-out, color .3s ease-out;

    @include hover {
      background-color: #74d0ed;
      color: #fff;
    }
  }
}