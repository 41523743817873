html {
  box-sizing: border-box;
  font-size: calc(100vw / 192);

  @media(max-width: 1024px) {
    font-size: calc(100vw / 39);
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
  background-color: #eff6f6;

  &.menu-active {
    .header {

      &__logo,
      &__buttons,
      &__top,
      &__burger,
      &__search {
        border-color: #fff;
      }

      &__btn {
        svg {
          fill: #fff !important;
        }
      }
    }

    .burger {
      &__line {
        background-color: #fff;

        &::before,
        &::after {
          background-color: #fff;
        }
      }
    }
  }
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.title2 {
  margin: 0;
  font-weight: 700;
  font-size: 6.4rem;
  text-transform: uppercase;
  text-align: center;
  color: #397a9e;

  @include tablet {
    font-size: 4rem;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  border: 1px solid #fff;
  border-radius: 2rem;
  padding: 1.5rem 4rem 2rem 4rem;
  background-color: #74d0ed;

  font-weight: 400;
  font-size: 2.8rem;
  text-align: center;
  color: #fff;
}

.main-slider {
  min-height: 0;

  height: 100vh;
  z-index: 100;


  @include tablet {
    // height: unset;
  }

  &>.swiper-wrapper {
    flex-direction: column;

    &>.swiper-slide {
      height: 100vh;
      height: auto;
      overflow: hidden;
      box-sizing: border-box;



      &:last-child,
      &:first-child {
        overflow-y: auto;
      }

      @include tablet {
        // height: unset;
      }
    }
  }
}

.to-main {
  position: absolute;
  right: 10rem;
  bottom: 5rem;

  padding: 2rem 3rem;
  font-size: 3rem;
  font-weight: 700;
  border-radius: 1rem;
  border: 1px solid #2EA8D5;
  color: #2EA8D5;
  white-space: nowrap;

  @include tablet {
    right: 50%;
    transform: translateX(50%);
    padding: 1rem 2rem;
    font-size: 2rem;
  }
}

.main-container {
  transition: opacity .6s ease-out;
}