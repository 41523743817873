.new {
  padding: 18rem 0;

  &__container {
    position: relative;
    z-index: 2;
  }

  &__title {
    margin: 0;
    margin-bottom: 1.5rem;
    text-align: left;
    line-height: 1;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 95rem;
    height: 96rem;
    z-index: -1;
  }

  &__header {
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-end;
    gap: 3.6rem;
    max-width: 101.8rem;
    margin-left: auto;
    margin-right: calc((100% - var(--container-width)) / 2);
  }

  &__left {
    flex-shrink: 0;
  }

  &__right {
    width: 100%;
    flex-grow: 1;
  }

  &__control {
    display: flex;
    gap: 2rem;
  }

  &__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 7rem;
    border: 1px solid #fff;
    user-select: none;

    svg {
      width: 3.8rem;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    height: 7rem;

    .swiper-pagination {
      --swiper-pagination-progressbar-bg-color: #97b9cc;
      --swiper-pagination-color: #c3ebf5;
      --swiper-pagination-progressbar-size: .7rem;
      position: static;
      width: 100%;
    }
  }

  &__slider {
    padding-left: 54rem;
    padding-right: 13rem;
    margin-bottom: 2rem;

    .swiper-slide {
      width: auto;
      height: auto;
      box-sizing: border-box;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__link {
    width: 50%;
  }
}


.new-item {
  width: 40rem;
  box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: #61a1c3;

  padding: 4rem;
  padding-top: 5rem;
  position: relative;
  height: 100%;
  transition: background-color .3s ease-out;

  @include hover {
    background-color: #3fb4f4;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__theme {
    position: absolute;
    left: 1.4rem;
    top: 1.4rem;
    padding: 1rem;
    border: 1px solid #fff;
    border-radius: .5rem;

    font-weight: 600;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #fff;
  }

  &__image {
    position: relative;
    padding: 0 3rem;
    z-index: 2;
    height: 25rem;
    margin-bottom: 4.5rem;

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 80%;
      background: radial-gradient(ellipse at center, rgba(#fff, 0.2), transparent);
      background-position: center;
      border-radius: 100%;
      filter: blur(5px);
    }
  }


  &__title {
    display: block;
    max-width: 21.8rem;
    margin: 0 auto;
    margin-bottom: 3.5rem;
    text-align: center;
    font-weight: 400;
    font-size: 2.4rem;
    color: #fff;

  }

  &__list {
    margin-bottom: 4.5rem;
  }

  &__item {
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #fff;
    font-weight: 400;
    font-size: 1.8rem;
    color: #fff;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
  }

  &__btn {
    flex-shrink: 0;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .4rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__price {
    flex-grow: 1;
    display: block;
    text-align: center;
    font-weight: 600;
    font-size: 2.8rem;
    color: #fff;
    padding-bottom: 1rem;
    border-bottom: 1px solid #fff;
  }
}