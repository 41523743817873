.hero {
  height: 100vh;
  padding-top: 32rem;
  position: relative;
  z-index: 2;

  @include tablet {
    height: var(--app-height);
    padding-top: 16rem;
    padding-bottom: 20rem;
  }

  &__container {
    pointer-events: none;
  }

  &__top {
    padding-bottom: 5rem;
    margin-bottom: 5rem;
    border-bottom: 1px solid var(--text-color);
    pointer-events: none;
  }

  &__title {
    margin: 0;
    margin-bottom: 5rem;
    font-weight: 700;
    font-size: 9.6rem;
    text-transform: uppercase;
    color: #2EA8D5;

    span {
      display: inline-flex;
      align-items: baseline;

      svg {
        height: 8rem;
        width: auto;
      }
    }

    @include tablet {
      font-size: 5rem;
      margin-bottom: 2rem;
    }
  }

  &__subtitle {
    margin: 0;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 4.8rem;
    text-transform: uppercase;
    color: #2EA8D5;

    @include tablet {
      font-size: 2.4rem;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    gap: 30rem;

    @include tablet {
      flex-direction: column;
      gap: 10rem;
    }
  }

  &__desc {
    max-width: 84rem;
    margin: 0;
    font-weight: 600;
    font-size: 2rem;
    text-transform: uppercase;
    color: #2EA8D5;

    @include tablet {
      max-width: 100%;
      font-size: 1.8rem;
    }

    &:first-child {
      @include tablet {
        display: none;
      }
    }
  }

  &__background {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    canvas {
      width: 100%;
      height: 100%;
    }
  }
}