// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Segoe UI", sans-serif;
  --content-width: 166rem;
  --container-offset: 1rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --text-color: #397a9e;
}